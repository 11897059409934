import React from 'react';
import img from '../images/intro.png';

const Intro = () => {
    return (
        <>
                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='intro' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <img alt="card img" className="rounded-t float-right max-h-[470px]" src={img} />
                        </div>
                        <div className="flex-col my-5 text-center lg:text-left lg:my-40 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            
                            <h3 className="text-3xl  text-[#09746e] font-bold">A Impulse Health</h3>
                            <div>
                                <p className='my-4 text-xl text-gray-600 font-semibold'>Nasceu
                                        no coração de Brasília construindo relacionamentos de
                                        valor, hoje já atendemos em todo o país. Nossos
                                        profissionais com mais de 20 anos de experiência
                                        inseridos no mercado da Saúde Suplementar, entregam
                                        um trabalho de excelência, buscando inovar e resultar
                                        junto ao nossos clientes em grandes conquistas.</p>
                            </div>
                            <br></br>
                            <br></br>
                            <div>
                                <p className='my-4 text-2xl text-[#602778] font-bold'>Estamos disponíveis no whatsapp!</p>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    )
}

export default Intro;