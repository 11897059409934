import React from 'react';

const Portfolio = () => {
    return (
        <>
            <div className="my-4 py-4" id='servicos'>
                <h2 className="my-2 text-center text-3xl text-[#09746e] uppercase font-bold">Serviços</h2>
                <div className='flex justify-center'>
                    <div className='w-24 border-b-4 border-blue-900 mb-8'></div>
                </div>

                <div className="px-4" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">
                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">CONSULTORIA JURIDICA – GOVERNANÇA E COMPLIANCE
                                </h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                    Definir Mecanismos e Princípios com boas práticas de gestão e
                                    normas éticas;
                                    ·Análise e Diagnóstico de Conformidade;
                                    ·Avaliação dos processos jurídicos e administrativos em geral;

                                </p>
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">CONSULTORIA OPERACIONAL</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                    Adequação dos fluxos e Acolhimento do Beneficiário;
                                    ·Treinamento e Ajuste de Processos dentro dos padrões do órgão
                                    fiscalizador;
                                    ·Ajuste de indicadores em conformidade com a legislação;
                                    ·Avaliação para selos de qualidade
                                </p>
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">CONSULTORIA REGULATÓRIA</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                    Soluções quanto à regularização de seus estabelecimentos ou
                                    produtos, junto aos diversos órgãos reguladores/fiscalizadores;
                                    ·Acompanhamento Técnico da Lei 9656/98 e correlatas;
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
                        <div className="flex flex-col-reverse lg:flex-row py-3 justify-between lg:text-left" data-aos="zoom-out">
                            <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">

                                <h3 className="text-3xl mb-1 text-[#09746e] font-bold">PORQUE NOS CONTRATAR</h3>
                            </div>
                        </div>
                        <p className='my-3 text-xl text-gray-600 mb-3 font-semibold'>
                            Entenda nossos diferenciais.
                            Atendimento 100% Personalizado;
                            Método Preventivo;
                            Didática SEM IGUAL para tratar do assunto;
                            Prezamos por uma relação de Parceria;
                            Buscamos desenvolver crescimento, eficiência e mais
                            competitividade;

                        </p>

                        <div className="flex flex-col-reverse lg:flex-row py-3 justify-between lg:text-left" data-aos="zoom-out"></div>
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <h3 className="text-3xl mb-1 text-[#09746e] font-bold">ESCOPO DE ATUAÇÃO</h3>
                        </div>
                        <p className='my-3 text-xl text-gray-600 mb-3 font-semibold'>
                            A prestação de serviços prevista nessa proposta consistirá
                            essencialmente em:
                            a)Mapeamento de cenário
                            b)Planejamento Estratégico para conformidade
                            c)Cronograma de operação
                            d)Implantação de soluções e ajustes
                            e)Indicadores de Melhorias
                            f)Validação de Resultado

                        </p>
                    </div>
                </section>

            </div>

        </>
    )
}

export default Portfolio;