import React from 'react';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
    return (
        <>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-[#09746e]" smooth to="/#home">
                Home    
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-[#09746e]" to="/#intro">
                A Empresa
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-[#09746e]" smooth to="/#quem">
                Quem Somos
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-[#09746e]" smooth to="/#convenios">
                Serviços
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-[#09746e]" smooth to="/#contato">
                Contato
            </HashLink>
        </>
    )
}

export default NavLinks;
