import React from 'react';
import helaine from '../images/Web-developer.svg'; 
import rayane from '../images/Web-developer.svg';
import viviane from '../images/Web-developer.svg';

const Services = () => {

    return (
        <div id="corpoclinico" className="bg-gray-100 py-12" >
            <section data-aos="zoom-in-down">
                    <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-[#09746e] uppercase font-bold">Quem Somos</h2>
                        
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-blue-900'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-[#09746e]">XXXXXXX.</h2>
                    </div>

                    
                    <div className="px-12 mb-6" data-aos="fade-down" data-aos-delay="600">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">
                            
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={helaine} />
                                    <h2 className="font-semibold my-4 text-2xl text-center text-[#09746e] ">XXX</h2>
                                    <h2 className="font-semibold my-4 text-2xl text-center">XXX</h2>
                                    <p className="text-md font-medium">
                                     XXX
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={rayane} />
                                    <h2 className="font-semibold my-4 text-2xl text-center text-[#09746e] ">XXX</h2>
                                    <h2 className="font-semibold my-4 text-2xl text-center">XXXX</h2>
                                    <p className="text-md font-medium">
                                    XX
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={viviane} />
                                    <h2 className="font-semibold my-4 text-2xl text-center text-[#09746e] ">XXX</h2>
                                    <h2 className="font-semibold my-4 text-2xl text-center ">XXXX</h2>
                                    <p className="text-md font-medium">
                                        XX  </p>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>
        </div>
    )
}

export default Services;