import React from 'react';
import NavBar from '../components/Navbar/NavBar';
import heroImg from '../images/logo_completo.png';

const Hero = () => {
    return (
        <>
            <div className="hero" id='home'>
                <div>
                    <NavBar />
                </div>
                
                <div className="m-auto overflow-hidden mx-5 mt-4 lg:mt-4 p-2 md:p-10 h-5/6" data-aos="zoom-in">

                    <div id='hero' className="flex flex-col mt-20 lg:flex-row py-8 justify-between text-center lg:text-left">
                        <div className="lg:w-1/2 flex flex-col justify-center" data-aos="zoom-in" data-aos-delay="200">
                            <h1 className="mb-5 md:text-4xl text-2xl font-bold text-[#09746e]">
                            {/* We build digital solutions to help businesses scale */}
                                A Impulse Health transforma seu negócio com inovação, segurança e confiança.
                            </h1>
                            <div className="text-xl font-semibold tracking-tight mb-2 text-gray-500">Olá! Vamos impulsar, conectar e otimizar empresas.</div>
                        </div>
                        <div className="flex lg:justify-end w-full lg:w-1/4" data-aos="fade-up" data-aos-delay="600">
                            <img alt="card img" className="rounded-t float-right duration-1000 w-full" src={heroImg} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Hero;