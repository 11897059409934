import React from 'react';
import { HashLink } from 'react-router-hash-link';
import instagram from '../images/icons/instagram.png';
import { Link } from 'react-router-dom';

const icon = {
    height: '40px',
    width: '40px',
    mixBlendMode: 'colorBurn',
    margin: 'auto'
}

const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer max-w-full mx-auto px-3 sm:px-6 bg-gray-80 border-t border-b py-30" id="contato">

                    {/* Top area: Blocks */}
                    <div className="grid sm:grid-cols-12 gap-4 py-8 md:py-12 border-t border-gray-100 lg:ml-11">

                        {/* 1st block */}
                        {/*<div className="col-span-3 text-center mx-auto lg:col-span-3 font-bold uppercase text-[#09746e]">
                            <div className="p-8 rounded-lg xl:w-80 mx-auto mb-6">
                                <img alt="heroImg" width="150" height="150" src={heroImg} />
                            </div>
                        </div>*/}

                        {/* 2nd block */}
                        <div className="col-span-4 text-center mx-auto lg:col-span-4  text-[#09746e]">
                            <h6 className="text-[#09746e] text-xl font-bold uppercase mb-6">CONTATOS</h6>
                            <div className="text-md font-bold mx-auto text-[#09746e]">
                                Tel. e WhatsApp
                            </div>
                            <div className="text-md mx-auto mb-4 text-[#09746e]">
                                (61)9000000
                            </div>
                            <div className="text-md font-bold mx-auto text-[#09746e]">
                                E-mail
                            </div>
                            <div className="text-md mx-auto text-[#09746e]">
                                impulsehealth@gmail.com
                            </div>

                        </div>

                        {/* 3rd block */}
                        <div className="col-span-4 text-center mx-auto lg:col-span-4 font-bold text-[#09746e]">
                            <h6 className="text-xl uppercase mb-6">Endereço</h6>
                            <div className="text-md mx-auto text-[#09746e]">
                            ImpulseHealth Consultoria Regulatória e Negócios
                            </div>
                            <div className="text-md mx-auto text-[#09746e]">
                                XXXX
                            </div>
                            <div className="text-md mx-auto  text-[#09746e]">
                                XXXX
                            </div>
                            <div className="text-md mx-auto mb-6 text-[#09746e]">
                                Distrito Federal
                            </div>
                            <div>

                            </div>
                        </div>

                        {/* 4th block */}
                        <div className="col-span-4 text-center lg:col-span-4 font-bold  text-[#09746e]">
                            <div className="text-xl uppercase mx-auto mb-6">
                                Mídias Sociais
                            </div>
                            <div style={icon} >
                                <Link to="https://www.instagram.com/" >
                                    <img src={instagram} alt="icone" />
                                </Link>
                            </div>
                            <div className="text-md mx-auto mb-6">
                                @ImpulseHealth Consultoria Regulatória e Negócios
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">
                        <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">
                            <div className="text-sm text-gray-200 font-semibold py-1">
                                Copyright &copy; {new Date().getFullYear()}{"  "}
                                <HashLink
                                    to="#"
                                    className=" hover:text-gray-900"
                                >

                                </HashLink>.  Todos os direitos reservados.
                            </div>
                        </div>
                    </div>

                </div>

            </footer>
        </>
    )
}
export default Footer;
